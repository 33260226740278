.screen {
  min-height: 100vh;
  height: auto;
  background-size: cover;
  background-position: center bottom;
  padding: 50px 0px;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;

  &.banner{
    .slick-dots {
      bottom: 25px;
      @include breakpoint(small only){
        bottom: 0px;
      }
    }

    &.screen .banner-two {
      margin-bottom: 0px !important;

      .slick-dots {
        bottom: 25px;
      }

      .grid-left-center {
        @include breakpoint(small only){
          padding-bottom: 50px;
        }
      }
    }
  }

  &.no-padding{
    padding: 0px;
  }

  .grid-container {
    height: 100%;
    @include breakpoint(small only){
      min-height: 100vh;
    }
  }

  .grid-x {
    min-height: 100%;
    min-height: 100vh;
    @include breakpoint(small only){
      min-height: 100vh;
    }
  }

  .grid-left-center {
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    @include breakpoint(small only){
      align-items: flex-end;
    }
  }

  .grid-right-center {
    text-align: right;
    align-content: center;
    justify-content: flex-end;
    align-items: center;

    .box-list{
      li {
        background: url('/static/frontend/dist/images/base/box.jpg') no-repeat right center;
        padding-right: 30px;
      }
    }
  }

  .grid-left-bottom {
    align-content: initial;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .grid-top-center {
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    @include breakpoint(small only){
      margin: 0px;
    }
  }

  h1 {
    font-family: 'HelveticaNeueCyr';
    font-weight: 300;
    text-transform: uppercase;
    line-height: 3rem;
    font-size: 3rem;

    @include breakpoint(small only){
      font-size: 2.5rem;
      line-height: 2.4rem;
      text-align: center;
    }
  }

  h2 {
    color: $second-color;
    font-family: 'HelveticaNeueCyr';
    line-height: 2rem;
    font-size: 2rem;
    margin-bottom: 40px;
    @include breakpoint(small only){
      font-size: 2.5rem;
      line-height: 2.4rem;
      text-align: center;
    }
  }

  h3 {
    font-family: 'Ubuntu';
    line-height: 3rem;
    font-size: 1.6rem;

  }

  a {
    color: $footer-color;
    text-decoration: underline;
    &:hover{
      color: $black;
    }
  }

  .column-text {
    width: 350px;
  }

  .box-shadow {
    -webkit-box-shadow: 21px 27px 50px -16px rgba(0,0,0,0.75);
    -moz-box-shadow: 21px 27px 50px -16px rgba(0,0,0,0.75);
    box-shadow: 21px 27px 50px -16px rgba(0,0,0,0.75);
  }

  .subheader {
      color: $black;
      margin: 0px;
      margin-bottom: 30px;
      font-size: 1.3rem;

      @include breakpoint(small only){
        text-align: center;
      }
    }

  .background-header {
    display: block;
    overflow: hidden;
    padding: 10px;

    h2 {
      background-color: $first-color;
      display: inline;
      padding: 0px;
      box-shadow: -8px 0 0 1px $first-color, 8px 0 0 1px $first-color;
      @include breakpoint(small only){
        font-size: 2.5rem;
        line-height: 2.4rem;
      }
    }
    .subheader{
      display: block;
      margin: 10px 0px;
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 15px;
        background-color: $first-color;
        position: relative;
        margin-right: -100%;
        right: -14px;
      }
    }

    &.width-auto{
      width: auto !important;
    }
  }

  .background-header-min {
    display: block;
    overflow: hidden;
    width: 510px;
    h2 {
      font-family: 'HelveticaNeueCyr';
      font-weight: bold;
      text-transform: none;
      margin-bottom: 0px;
      @include breakpoint(small only){
        font-size: 2rem;
      }

      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 15px;
        background-color: $first-color;
        position: relative;
        margin-right: -100%;
        right: -14px;
        top: 5px;
      }
    }

    .subheader{
      font-family: 'Ubuntu';
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 15px;
        background-color: $first-color;
        position: relative;
        margin-right: -100%;
        right: -14px;
      }
    }
  }

  .box-list{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    margin-bottom: 30px;

    li {
      font-size: 1.1rem;
      display: block;
      margin: 20px 0px;
      background: url('/static/frontend/dist/images/base/box.jpg') no-repeat left center;
      padding-left: 30px;
    }
  }

  .box-list-point-none{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    margin-bottom: 30px;

    li {
      font-size: 1.1rem;
      display: block;
      margin: 20px 0px;
      padding-left: 10px;
    }
  }

  .text {
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: $first-color;

    @include breakpoint(small only){
      text-align: center;
    }

    i{
      font-size: 2.3rem;
      float: left;
      margin-right: 15px;
      color: $second-color;
    }
  }

  .medium-text{
    font-size: 1.2rem;
    line-height: 1.4rem;

    i{
      font-size: 4rem;
      float: left;
      margin-right: 15px;
      color: $second-color;
    }
  }

  .image-ipx7{
    width: 120px;
    float: left;
  }

  .gradient-button{
    padding: 15px 20px;
    border-radius: 30px;
    background: $gradient-one-color;
    background: -webkit-linear-gradient(top, $gradient-one-color, $gradient-two-color);
    background: -moz-linear-gradient(top, $gradient-one-color, $gradient-two-color);
    background: linear-gradient(to bottom, $gradient-one-color, $gradient-two-color);
    margin: 10px 0px;
    display: inline-block;
    color: $white;
    text-decoration: none;

    &:hover{
      background: $gradient-one-color +20;
      background: -webkit-linear-gradient(top, $gradient-one-color +20, $gradient-two-color +20);
      background: -moz-linear-gradient(top, $gradient-one-color +20, $gradient-two-color +20);
      background: linear-gradient(to bottom, $gradient-one-color +20, $gradient-two-color +20);
      color: $white;
    }
  }

  .play-button {
    text-align: center;
    cursor: pointer;
    @include breakpoint(small only){
      margin-top: 50px;
      margin-bottom: 50px;
    }
    a{
      display: none;
    }

    i{
      font-size: 4rem;
      color: $white;
      &:hover{
        color: $second-color;
      }
    }
  }

  &.fly-image-item {
    position: relative;
    background-position: bottom right;

    .image-item-1 {
      position: absolute;
      width: 200px;
      right: 10%;
      top: 25%;
      z-index: 1;
      -webkit-transition: top 1.1s ease;
      transition: top 1.1s ease;
      @include breakpoint(small only){
        display: none;
      }
      &.animated-item{
        top: 8%;
      }
    }

    .image-item-2 {
      position: absolute;
      width: 180px;
      right: 38%;
      top: 30%;
      z-index: 1;
      -webkit-transition: top 1.1s ease;
      transition: top 1.1s ease;
      @include breakpoint(small only){
        display: none;
      }
      &.animated-item{
        top: 19%;
      }
    }

    .grid-container {
      z-index: 2;
      position: relative;
    }
  }

  &.font-white {
    color: $white;
    h2 {
      color: $white;
    }
    .subheader {
      color: $white;
    }
  }

  .svg-sport{
    display: inline-block;
    width: 50px !important;
    height: 50px !important;
    background-size: 50px 50px !important;

    @include breakpoint(small only){
      background-size: 40px 40px !important;
      width: 40px !important;
      height: 40px !important;
    }
  }

  .model-slider {
    height: 200px;
    overflow: hidden;

    img{
      width: 200px;
      margin: 0px auto;
    }

    .slick-dots{
      margin: 10px 0px;
      padding: 0px;
      list-style-type: none;
      li{
        display: inline-block;
        button{
          font-size: 0;
          width: 15px;
          height: 15px;
          cursor: pointer;
          background: $white;
          border-radius: 10px;
          border: 1px solid $light-gray;
          margin: 0px 2px;
          outline: none;
        }

        &.slick-active{
          button{
            border-color: $dark-gray;
          }
        }
      }
    }
    .slick-slide{
      outline: none;
    }
  }

  .model-slider-color {
    height: 20px;
    overflow: hidden;
    width: 200px;
    margin: 0px auto;
    margin-top: 20px;

    div{

      i{
        width: 15px;
        height: 15px;
        border-radius: 20px;
        display: inline-block;
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
      }

    }

    .slick-current{
      i{
        border: 1px solid $dark-gray;
      }
    }

    .slick-slide{
      outline: none;
    }

    .slick-track {
      transform: initial !important;
      margin: 0px auto;
    }
  }

  .model {
    text-align: center;
    .name {
      font-size: 2rem;
      @include breakpoint(medium only) {
        height: 80px;
        line-height: 2rem;
      }
    }
    .cartion{
      font-size: 1rem;
      line-height: 1rem;
      @include breakpoint(medium only){
        height: 40px;
      }
    }
  }

  .appstore-button{
    float: left;
    margin: 0px 10px;
    width: 100px;

    @include breakpoint(small only){
      width: 150px;
    }
  }

  .action-button{
    margin-top: 30px;
    @include breakpoint(small only){
      margin-bottom: 50px;
    }
    a{
      border: 1px solid $second-color;
      border-radius: 20px;
      padding: 10px 20px;
      color: $black;
      text-transform: uppercase;
      font-size: 1rem;
      &:hover{
        background-color: $first-color;
        border-color: $first-color;
      }
    }
  }

  .main-button{
      border: 1px solid $second-color;
      border-radius: 40px;
      padding: 10px 20px;
      color: $black;
      text-transform: uppercase;
      font-size: 1rem;
    display: inline-block;
    margin-top: 20px;
      &:hover{
        background-color: $first-color;
        border-color: $first-color;
      }

  }

  .next-button {
    color: $second-color;
    position: relative;
    bottom: 10px;
    display: block;
    font-size: 2rem;
    text-align: center;
    height: 50px;
    svg{
      width: 30px;
      transition: all .3s ease;
      .stroke{
        stroke: $second-color;
      }
      .scroller{
        fill: $second-color;
        -webkit-animation: updown 1s infinite;
        -moz-animation: updown 1s infinite;
        animation: updown 1s infinite;
      }
    }
  }

  .pause-button{
    font-size: 2rem;
    position: absolute;
    top: 90%;
    left: 50px;
    display: inline-block;
    color: $white;

    @include breakpoint(small only){
     font-size: 3rem;
      top: 85%;
    }

    &:hover{
      color: $second-color;
    }
  }

  &.padding-0{
    padding: 0px;
    overflow: hidden;
  }

  .fastening-slider {
    height: 100%;
    background-color: #ccc;
    overflow: hidden;

    .slick-list, .slick-track{
      height: 100%;
      overflow: hidden;
    }

    .fastening-image{
      background-size: cover;
      height: 100%;
      background-position: center bottom;
    }
    .slick-slide{
      outline: none;
    }
  }

  .video-block{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .video-player{
      min-width: 100%;
      min-height: 100%;
      display: inline;
      @include breakpoint(small only){
        display: none;
      }
      @include breakpoint(medium only){
        display: none;
      }
    }
  }

  .position-relative{
    position: relative;
  }

  .video-home{
    height: 100%;
    display: none;
    position: relative;

    @include breakpoint(small only){
      min-height: 100vh;
    }

    @include breakpoint(medium only){
      min-height: 100vh;
    }

    &:before{
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
    }
    iframe{
      width: 100%;
      height: 100%;

      @include breakpoint(small only){
        min-height: 100vh;
      }

      @include breakpoint(medium only){
        min-height: 100vh;
      }
    }
  }

  .screen-link{
    @include breakpoint(small only){
      display: none;
    }
  }

  &.models{
    height: auto !important;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    .grid-container{
      width: 100%;
    }
  }

  &.fastening{
    @include breakpoint(small only){
      min-height: 100%;
    }
  }

  &#proof_video_banner{
    @include breakpoint(small only){
      padding: 50px 0px;
    }
  }

  .icon-swipe{
    display: block;
    position: relative;
    bottom: 100px;
    font-size: 60px;
    text-align: center;
    color: $second-color;
  }

  .color-change {
    margin: 10px 0px;
    display: flex;
    cursor: pointer;
    color: $dark-gray;
    width: 150px;

    @include breakpoint(small only){
      width: auto;
      align-items: end;
      align-content: center;
      justify-content: center;
    }


    &#color-box-black{
      span {
        i {
          background-color: #121212;
        }
      }
    }

    &#color-box-gold{
      span {
        i {
          background-color: #C7B098;
        }
      }
    }


    &:hover{
      p{
        color: $black;
        text-decoration: underline;
      }
      span {
        border-color: $black;
      }


    }

    p{
      margin-left: 10px;
    }
    span {
      display: inline-block;
      border: 1px solid $dark-gray;
      padding: 3px;
      margin: 0px;
      height: 28px;
      i {
        background-color: #121212;
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    }
  }

  .specifications-tabs-container {

    color: $first-color;

    .tabs {
      border: none;
      background: transparent;
    }

    .tabs-content {
      border: none;
    }

    .tabs-panel {
      padding: 0px;
    }

    .tabs-title {
      @include breakpoint(small only){
        width: 100%;
      }
    }

    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: $first-base-color;
      color: $footer-text-color;
      text-decoration: none;
      outline:none;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 85px;
        bottom: -20px;
        border: 10px solid transparent;
        border-top: 10px solid $first-base-color;

        @include breakpoint(small only){
          display: none;
        }
      }

      &:hover {
        background: $first-base-color;
      }
    }

    .tabs-title > a {
      color: $footer-text-color;
      text-decoration: none;
      outline:none;
      width: 200px;
      text-align: center;
      font-size: 0.9rem;
      border-right: 1px solid $first-base-color;

      @include breakpoint(small only){
        width: 100%;
      }

      &:hover {
        background: transparent;
      }
    }

    h3 {
      color: $black;
      border-bottom: 1px solid $light-gray;
      padding: 10px 0px;
      line-height: 2rem;
    }

    strong {
      display: block;
      color: $footer-text-color;
      margin-top: 20px;
    }

    .specifications {
      color: $first-color;
    }

    .download {
      color: $footer-text-color;
      a{
        color: $footer-text-color;
        &:hover{
          color: $black;
        }
      }
    }

    //.gallery-slider-for {
    //  height: 400px;
    //  width: 100%;
    //  div {
    //    height: 400px;
    //    width: 100%;
    //  }
    //}
  }

  .mobile-text-center {
    @include breakpoint(small only){
      text-align: center;
    }
  }
}

.pagination-menu {
  position:fixed;
  right:20px;
  top: 50%;
  transform: translateY(-50%);
  font-size:1.4em;
  z-index: 10;
  list-style: none;

  @include breakpoint(small only){
    display: none;
  }

  li {
    display: block;
  }
  a {
    display:block;
    height:20px;
    margin-bottom:5px;
    color: $black;
    position:relative;
    padding:4px;



    &.active:after {
      box-shadow:inset 0 0 0 5px;
    }
    & .hover-text {
      position:absolute;
      right:15px;
      top:-2px;
      opacity:0;
      -webkit-transition: opacity 0.5s ease;
      transition: opacity 0.5s ease;
      padding-right: 15px;
      font-size: 1rem;
      display: none;
      width: 200px;
      text-align: right;
    }
    &:hover .hover-text{
      opacity: 1;
      display: block;
    }
    &:after {
      -webkit-transition:box-shadow 0.5s ease;
      transition:box-shadow 0.5s ease;
      width:10px;
      height:10px;
      display: block;
      border:1px solid;
      border-radius:50%;
      content:'';
      position: absolute;
      margin:auto;
      top:0;
      right:4px;
      bottom:0;
    }
  }
}

footer {
  background: $footer-color;
  color: $footer-text-color;
  padding: 40px 0px;

  .border-top-line {
    border-top: 1px solid $footer-text-color;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  a {
    color: $footer-text-color;

    &:hover{
      color: $footer-text-color+20;
    }
    &.footer-menu-header{
      text-transform: uppercase;
      display: inline-block;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: $footer-text-color;

      @include breakpoint(small only) {
        text-align: center;
        display: block;
        border: none;
        text-decoration: underline;
      }
    }
  }

  .footer-menu{
    padding: 50px 0px;
    text-align: right;
    @include breakpoint(small only){
      text-align: center;
    }
    .item{

      display: inline-block;
      @include breakpoint(small only){
        display: block;
      }

      a{
        margin-right: 30px;
        color: $footer-text-color;
        @include breakpoint(small only){
          padding: 10px 15px;
          display: block;
        }
        &:hover{
          color: $footer-text-color + 20;
        }
      }

      ul {
        list-style-type: none;
        margin: 10px 0px;
        display: none;
        text-align: left;
        position: absolute;
        background: $light-gray;
        @include breakpoint(small only){
          position: static;
        }

        li {
          display: block;
          margin: 0px;
          a {
            color: $footer-text-color;
            padding: 10px 15px;
            display: block;
            margin: 0px;
            &:hover{
              color: $footer-text-color+20;
              background-color: $medium-gray;
            }
          }
        }
        @include breakpoint(small only) {
          text-align: center;
          margin-bottom: 30px;
        }
      }
    }


  }

  a.footer-copyright-link{
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;

    &:hover {
      color: $footer-text-color;
    }

    @include breakpoint(small only) {
      margin: 20px 0px;
      justify-content: center;
    }

    i.fa {
      color: $heart-color;
      font-size: 0.8em;
      margin-right: 10px;
    }

    .footer-copyright {
      font-size: 0.8em;
      margin: 0px;
      line-height: 15px;
      margin-top: 3px;

    }
  }

  .footer-license {
    font-size: 0.8em;
    margin: 0px;
    line-height: 15px;
    text-align: justify;
    a{
      text-decoration: underline;
    }
  }

  .logo-footer {
    display: block;
    margin-top: 33px;

    background-position: center;
    background-repeat: no-repeat;
    height: 50px;
    background-size: 100%;

    background-image: url('/static/images/base/logo-bowers-and-wilkins.png');
    @include breakpoint(small only){
      width: 200px;
      margin: 0px auto;
    }
  }

  .social-links {
    text-align: right;
    font-size: 2.2rem;
    padding: 40px 0px;

    @include breakpoint(small only)
    {
      text-align: center;
      padding: 0px;
    }

    a{
      margin-right: 10px;
      display: inline-block;
    }
  }

  a.second-menu:after{
    content: "p";
    font-family: "LineIcons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    top: 4px;
    left: 8px;
  }
}

header {
  background-color: $bg-color;

  .grid-container{
    padding: 20px 20px;
  }

  .header-logo{
    background-position: center;
    background-repeat: no-repeat;
    height: 50px;
    background-size: 100%;
    display: block;
    background-image: url('/static/images/base/logo-bowers-and-wilkins.png');
  }

  a.mobile-menu{
    float: right;
    font-size: 2rem;
    color: $dark-gray;
    display: none;
    @include breakpoint(small only){
      display: block;
    }
  }

  ul.header-menu {
    list-style-type: none;
    margin: 10px 0px;
    padding: 0px;
    float: right;

    @include breakpoint(small only){
      position: absolute;
      width: 100%;
      left: 0px;
      top: 90px;
      background-color: $white;
      margin: 0px;
      z-index: 10;
    }


    li{
      display: inline-block;
      margin: 0px 15px;
      @include breakpoint(small only){
        display: block;
        border-bottom: 1px solid $dark-gray;
        margin: 0px;
        background-color: $white;
      }

      &:last-child{
        margin: 0px;
        margin-left: 20px;
        @include breakpoint(small only){
          margin: 0px;
          border: 0px;
        }
      }

      ul {
        list-style-type: none;
        margin: 10px 0px;
        display: none;
        text-align: left;
        position: absolute;
        background: $white;
        border: 1px solid $light-gray;
        z-index: 10;
        @include breakpoint(small only){
          position: static;
          margin: 0px;
          border: 0px;
          border-top: 1px solid $dark-gray;
        }

        li {
          display: block;
          margin: 0px !important;
          a {
            color: $first-color;
            display: block;
            padding: 15px 30px;
            @include breakpoint(small only){
              color: $black;
            }
            &:hover{
              color: $footer-color+20;
              background-color: $light-gray;
            }
          }
        }
      }

      a{
        color: $first-color;
        @include breakpoint(small only){
          padding: 15px 10px;
          display: block;
          text-align: center;
        }

        &:hover{
          color: $black + 100;
          @include breakpoint(small only){
            background-color: $light-gray;
          }
        }
      }
    }

    @include breakpoint(small only) {
      display: none;
    }
  }

  a.second-menu:after{
    content: "p";
    font-family: "LineIcons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    top: 4px;
    left: 8px;
  }
}

.product{
  width: 100%;
  overflow: hidden;

  .product-photo-sliders-block{
    max-height: 500px;
    overflow: hidden;
  }

  .color-image-slider{
    display: none;
    &.visible{
      display: block;
    }

    .color-image-slider-large{
      max-width: 400px;
      margin: 0px auto;
      margin-bottom: 20px;
      max-height: 400px;
      overflow: hidden;
      .slick-slide{
        outline: none;
      }
    }

    .color-image-slider-thumb{
      max-width: 400px;
      margin: 0px auto;
      max-height: 70px;
      overflow: hidden;
      img{
        margin-right: 10px;
        cursor: pointer;
      }
      .slick-slide{
        outline: none;
      }
    }

  }

  .info{

    h1{
      margin: 0px;
      @include breakpoint(small only){
        font-size: 3rem;
      }
    }

    .caption{
      display: block;
      margin: 0px;
      font-size: 1.1rem;
      overflow: hidden;
      border-bottom: 1px solid $second-color;
      padding-bottom: 20px;
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 15px;
        background-color: $first-color;
        position: relative;
        margin-right: -100%;
        right: -14px;
      }
    }

    .list {
      ul {
        padding: 0px;
        list-style-type: none;
        margin: 20px 0px;
        margin-left: 0.7rem;

        li {
          font-size: 1.1rem;
          display: block;
          margin: 10px 0px;
          background: url('/static/frontend/dist/images/base/box.jpg') no-repeat left center;
          padding-left: 30px;
        }
      }
    }

    .colors{
      span{
        color: $medium-gray;
        font-size: 1rem;
        display: block;
        margin-bottom: 10px;
        @include breakpoint(small only){
          text-align: center;
        }
      }
      ul{
        list-style: none;
        margin: 0px;
        padding: 0px;
        @include breakpoint(small only){
          text-align: center;
        }
        li{
          a{
            display: inline-block;
            color: $black;
            padding: 5px 10px;
            &:hover{
              color: $second-color;
              background: $light-gray;
              border-radius: 20px;
            }
            i{
              width: 10px;
              height: 10px;
              border-radius: 20px;
              display: inline-block;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .links {
      @include breakpoint(small only){
        text-align: center;
      }
      span{
        color: $medium-gray;
        font-size: 1rem;
        display: block;
        margin-bottom: 10px;
      }
      a{
        padding: 5px 7px;
        color: $black;
        i{
          font-size: 1.2rem;
        }
      }
    }

    .svg-icon.icon-bluetooth {
      width: 60px;
      height: 60px;
      background-size: auto;
      margin-top: 20px;
      @include breakpoint(small only){
        margin: 20px auto;
      }
      @include breakpoint(medium only){
        margin: 20px auto;
      }
    }
  }

  .media-markt-button{
    margin-top: 20px;
    text-align: left;
    @include breakpoint(small only){
      text-align: center;
    }
    img{
      width: 50%;
      @include breakpoint(small only){
        display: inline-block;
        width: 50%;
      }
      @include breakpoint(medium only){
        width: 100%;
        margin-top: 10px;
      }
    }
    a{
      display: inline-block;
      padding: 10px 25px;
      background-color: #ca0088;
      color: $white;
      border-radius: 5px;
      text-align: center;
      font-size: 1rem;
      @include breakpoint(medium only){
        width: 100%;
      }
      &:hover{
        background-color: #ca0088 + 20;
      }
    }
  }
}

.content{
  padding: 60px 0px;
  width: 100%;
  overflow: hidden;

  h1 {
    font-family: 'HelveticaNeueCyr';
    font-weight: 300;
    text-transform: uppercase;
    line-height: 3rem;
    font-size: 3rem;
    text-align: center;
    margin: 40px 0px;
    @include breakpoint(small only){
      margin-top: 0px;
    }
    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 15px;
      background-repeat: no-repeat;
      background-image: url('/static/frontend/images/base/header-right.png');
      background-position: left;
      position: relative;
      margin-right: -100%;
      right: -14px;
      top: -3px;
      @include breakpoint(small only){
        display: none;
      }
    }
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 15px;
      background-repeat: no-repeat;
      background-image: url('/static/frontend/images/base/header-left.png');
      background-position: right;
      position: relative;
      margin-left: -100%;
      left: -14px;
      top: -3px;
      @include breakpoint(small only){
        display: none;
      }
    }
  }
  h2 {
    font-family: 'HelveticaNeueCyr';
    font-weight: 300;
    text-transform: uppercase;
    line-height: 3rem;
    font-size: 1.7rem;
    text-align: center;
    margin: 40px 0px;
    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 15px;
      background-repeat: no-repeat;
      background-image: url('/static/frontend/images/base/header-right.png');
      background-position: left;
      position: relative;
      margin-right: -100%;
      right: -14px;
      top: -3px;
      @include breakpoint(small only){
        display: none;
      }
    }
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 15px;
      background-repeat: no-repeat;
      background-image: url('/static/frontend/images/base/header-left.png');
      background-position: right;
      position: relative;
      margin-left: -100%;
      left: -14px;
      top: -3px;
      @include breakpoint(small only){
        display: none;
      }
    }
  }
  .text{
    font-size: 1rem;
    line-height: 1rem;
    border-bottom: 1px solid $light-gray;
    padding: 30px 0px;

  }
  .text-center{
    text-align: center;
  }
  .video-product{
    height: 80vh;
    iframe{
      width: 100%;
      height: 100%;
    }
  }
}

.comparison-table{
  thead{
    th{
      background: $white;
      padding: 20px;
      text-align: center;
    }
    &:first-child{
      text-align: left;
    }
  }


  table tbody tr:nth-child(even) {
    background-color: transparent;
  }

  table tbody tr:nth-child(4n+2) {
    border-bottom: 0;
    background-color: #f1f1f1;
  }

  @include breakpoint(small only){
    table tbody tr:nth-child(4n+2) {
      background-color: transparent;
    }

    table tbody tr:nth-child(2n+1) {
      border-bottom: 0;
      background-color: #f1f1f1;
    }
  }


  .table-first{

    .table-logo{
      width: 170px !important;
      height: 80px !important;
      background-size: auto !important;
      margin-bottom: 20px;
    }

    p{
      font-weight: normal;
      text-transform: uppercase;
      text-align: left;
    }
  }

  .table-model{
    a{
      color: $black;
      text-decoration: none;
    }
    img {
      width: 150px;
      margin: 0px auto;
    }
    span{
      display: block;
      font-weight: normal;
    }
    .colors{
      i{
        width: 10px;
        height: 10px;
        border-radius: 20px;
        display: inline-block;
      }
    }
  }

  td {
    text-align: center;

    &:first-child{
      text-align: left;
      @include breakpoint(small only){
        text-align: center;
      }
    }
  }

}

.shops {
  width: 100%;
  overflow: hidden;

  h3 {
    text-align: center;
    margin: 40px 0px;
  }

  .grid-container {
    height: auto;
  }

  .grid-x {
    min-height: auto;
  }

  .city-input{
    margin-bottom: 30px;
    text-align: center;
    span{
      font-size: 1.4rem;
      display: block;
      margin-bottom: 20px;
    }
    select{
      width: 30%;
      border: 1px solid $first-color;
      border-radius: 0px;
      @include breakpoint(small only){
        width: 100%;
      }

      @include breakpoint(medium only){
        width: 50%;
      }
    }
  }
  .map{
    height: 60vh;
    background-color: $light-gray;
  }
  .shops-slider{
    margin: 30px 0px;
    display: none;
    overflow: hidden;
    width: 100%;
    .shop-item{
      color: $black;
      text-align: center;
      .image{
        height: 140px;
        margin-bottom: 10px;
        img{
          max-width: 70%;
          max-height: 100%;
          margin: 0px auto;
        }
      }
      .text{
        font-size: 1rem;
        line-height: 1.5rem;
        strong{
          display: block;
        }
        .address{

          @include breakpoint(small only) {
            display: block;
          }
        }
      }
    }

    &.visible{
      display: block;
    }
    .slick-slide{
      outline: none;
    }
  }
  .navigate-arrow{

    text-align: center;

    #shops-slider-prev-arrow{
      @include breakpoint(small only){
        text-align: right;
      }
    }

    i{
      cursor: pointer;
      font-size: 4rem;
      margin-top: 70px;
      display: block;
      outline: none;
      @include breakpoint(small only){
        margin: 0px;
        margin-bottom: 70px;
        display: inline-block;
      }
    }
  }

  a{
    outline: none;
  }
}

#banner {
  background-image: url('/static/images/base/1-pX-wireless-headphones-flora.jpg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;

  h2 {
    color: $black;
  }

  @include breakpoint(small only){
    background-image: url('/static/images/base/1-pX-wireless-headphones-flora-s.jpg');
    h2 {
      color: $white;
    }
  }
}

#review {
  background-position: top right;
  background-size: contain;
  background-image: url('/static/images/base/2-2-px-wireless-headphones-controlled-by-instinct.jpg');
  background-color: #242F46;
  background-repeat: no-repeat;
  @include breakpoint(small only){
    background-image: url('/static/images/base/2-2-px-wireless-headphones-controlled-by-instinct-s.jpg');
  }
  @include breakpoint(medium only) {
    background-image: url('/static/images/base/2-2-px-wireless-headphones-controlled-by-instinct-s.jpg');
    background-position: center right;
    background-size: contain;
  }
}

#banner-one-1 {
  background-image: url('/static/images/base/banner/3-1-px-wireless-headphones-best-in-class-sound.jpg');
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  @include breakpoint(small only){
    background-image: url('/static/images/base/banner/3-1-px-wireless-headphones-best-in-class-sound-s.jpg');
  }
}

#banner-one-2 {
  background-image: url('/static/images/base/banner/3-2-px-wireless-headphones-advanced-drivers.jpg');
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  @include breakpoint(small only){
    background-image: url('/static/images/base/banner/3-2-px-wireless-headphones-advanced-drivers-s.jpg');
  }
}

#banner-one-3 {
  background-image: url('/static/images/base/banner/3-3-px-wireless-headphones-aptx.jpg');
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  @include breakpoint(small only){
    background-image: url('/static/images/base/banner/3-3-px-wireless-headphones-aptx-s.jpg');
  }
}

#banner-one-4 {
  background-image: url('/static/images/base/banner/3-4-px-wireless-headphones-digitally-optimised.jpg');
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  @include breakpoint(small only){
    background-image: url('/static/images/base/banner/3-4-px-wireless-headphones-digitally-optimised-s.jpg');
  }
}

#noise {
  background-position: top right;
  background-size: contain;
  background-image: url('/static/images/base/4-px-wireless-headphones-noise-cancelling.jpg');
  background-color: #6d7a83;
  background-repeat: no-repeat;
  @include breakpoint(small only){
    background-image: url('/static/images/base/4-px-wireless-headphones-noise-cancelling-s.jpg');
    background-size: cover;
  }
  @include breakpoint(medium only) {
    background-image: url('/static/images/base/4-px-wireless-headphones-noise-cancelling-s.jpg');
    background-position: center right;
    background-size: contain;
  }

  img {
    height: 40px;
    width: 197px;
  }
}

#banner-two-1 {
  background-image: url('/static/images/base/banner/5-1-px-wireless-headphones-play-for-longer.jpg');
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;

  img {
    width: 100px;
    height: 40px;
    margin-top: 20px;
  }
}

#banner-two-2 {
  background-image: url('/static/images/base/banner/5-2-px-wireless-headphones-ready-to-travel.jpg');
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}

#banner-two-3 {
  background-image: url('/static/images/base/banner/5-3-px-wireless-headphones-high-quality-materials.jpg');
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}

#banner-two-4 {
  background-image: url('/static/images/base/banner/5-4-px-wireless-headphones-ergonomic-design.jpg');
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}

#banner-two-5 {
  background-image: url('/static/images/base/banner/5-5-px-wireless-headphones-built-to-last.jpg');
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}

#app {
  background-position: top right;
  background-size: cover;
  background-image: url('/static/images/base/6-px-wireless-headphones-tune-your-environment.jpg');
  background-repeat: no-repeat;
  background-color: #f5f5f5;

  @include breakpoint(small only) {
    background-image: url('/static/images/base/6-px-wireless-headphones-tune-your-environment-s.jpg');
    background-size: contain;
    background-position: top right;
  }

  @include breakpoint(medium only) {
    background-position: center right;
    background-size: contain;
  }
}

#specifications {
  background-image: url('/static/images/base/review-top-background.png');
  background-size: auto;
  background-position: 0 top;
  background-repeat: repeat-x;

  margin-bottom: 60px;
}

#shops {
  background-image: url('/static/images/base/review-top-background.png');
  background-size: auto;
  background-position: 0 top;
  background-repeat: repeat-x;

  padding: 50px 0px;
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
}

#video{
    height: 100vh;
  background-image: url('/static/images/base/bg_video.jpg');
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;

  h2 {
    color: $white;
  }
}