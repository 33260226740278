/* font-family: "GothamProRegular"; */
@font-face {
    font-family: "GothamPro";
    src: url("../fonts/GothamProRegular/GothamProRegular.eot");
    src: url("../fonts/GothamProRegular/GothamProRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamProRegular/GothamProRegular.woff") format("woff"),
    url("../fonts/GothamProRegular/GothamProRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "GothamProBold"; */
@font-face {
    font-family: "GothamPro";
    src: url("../fonts/GothamProBold/GothamProBold.eot");
    src: url("../fonts/GothamProBold/GothamProBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamProBold/GothamProBold.woff") format("woff"),
    url("../fonts/GothamProBold/GothamProBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}
/* font-family: "GothamProBlack"; */
@font-face {
    font-family: "GothamPro";
    src: url("../fonts/GothamProBlack/GothamProBlack.eot");
    src: url("../fonts/GothamProBlack/GothamProBlack.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamProBlack/GothamProBlack.woff") format("woff"),
    url("../fonts/GothamProBlack/GothamProBlack.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
}
/* font-family: "GothamProItalic"; */
@font-face {
    font-family: "GothamPro";
    src: url("../fonts/GothamProItalic/GothamProItalic.eot");
    src: url("../fonts/GothamProItalic/GothamProItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamProItalic/GothamProItalic.woff") format("woff"),
    url("../fonts/GothamProItalic/GothamProItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}
/* font-family: "GothamProMedium"; */
@font-face {
    font-family: "GothamPro";
    src: url("../fonts/GothamProMedium/GothamProMedium.eot");
    src: url("../fonts/GothamProMedium/GothamProMedium.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamProMedium/GothamProMedium.woff") format("woff"),
    url("../fonts/GothamProMedium/GothamProMedium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}
/* font-family: "GothamProLight"; */
@font-face {
    font-family: "GothamPro";
    src: url("../fonts/GothamProLight/GothamProLight.eot");
    src: url("../fonts/GothamProLight/GothamProLight.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamProLight/GothamProLight.woff") format("woff"),
    url("../fonts/GothamProLight/GothamProLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 200;
}
/* font-family: "GothamProMediumItalic"; */
@font-face {
    font-family: "GothamPro";
    src: url("../fonts/GothamProMediumItalic/GothamProMediumItalic.eot");
    src: url("../fonts/GothamProMediumItalic/GothamProMediumItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamProMediumItalic/GothamProMediumItalic.woff") format("woff"),
    url("../fonts/GothamProMediumItalic/GothamProMediumItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 500;
}
/* font-family: "GothamProLightItalic"; */
@font-face {
    font-family: "GothamPro";
    src: url("../fonts/GothamProLightItalic/GothamProLightItalic.eot");
    src: url("../fonts/GothamProLightItalic/GothamProLightItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamProLightItalic/GothamProLightItalic.woff") format("woff"),
    url("../fonts/GothamProLightItalic/GothamProLightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 200;
}
/* font-family: "GothamProBoldItalic"; */
@font-face {
    font-family: "GothamPro";
    src: url("../fonts/GothamProBoldItalic/GothamProBoldItalic.eot");
    src: url("../fonts/GothamProBoldItalic/GothamProBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamProBoldItalic/GothamProBoldItalic.woff") format("woff"),
    url("../fonts/GothamProBoldItalic/GothamProBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: bold;
}
/* font-family: "GothamProBlackItalic"; */
@font-face {
    font-family: "GothamPro";
    src: url("../fonts/GothamProBlackItalic/GothamProBlackItalic.eot");
    src: url("../fonts/GothamProBlackItalic/GothamProBlackItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamProBlackItalic/GothamProBlackItalic.woff") format("woff"),
    url("../fonts/GothamProBlackItalic/GothamProBlackItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 900;
}
