$first-color: #999999;
$second-color: #333333;
$footer-color: #0b0b0b;
$footer-text-color: #666666;
$heart-color: #ff1d52;
$first-color-dark: #af8100;
$first-base-color: #CDCDCD;
$bg-color: #2D2D2D;


$gradient-one-color: #066297;
$gradient-two-color: #343197;


@import "_vendors/foundation_settings";
@import "node_modules/foundation-sites/scss/foundation";
@include foundation-everything;
@import "_common/main";