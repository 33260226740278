/* font-family: "QuizaProRegular"; */
@font-face {
    font-family: "QuizaPro";
    src: url("../fonts/QuizaProRegular/QuizaProRegular.eot");
    src: url("../fonts/QuizaProRegular/QuizaProRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/QuizaProRegular/QuizaProRegular.woff") format("woff"),
    url("../fonts/QuizaProRegular/QuizaProRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "QuizaProItalic"; */
@font-face {
    font-family: "QuizaPro";
    src: url("../fonts/QuizaProItalic/QuizaProItalic.eot");
    src: url("../fonts/QuizaProItalic/QuizaProItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/QuizaProItalic/QuizaProItalic.woff") format("woff"),
    url("../fonts/QuizaProItalic/QuizaProItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}