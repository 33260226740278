/* font-family: "UbuntuRegular"; */
@font-face {
    font-family: "Ubuntu";
    src: url("../fonts/UbuntuRegular/UbuntuRegular.eot");
    src: url("../fonts/UbuntuRegular/UbuntuRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/UbuntuRegular/UbuntuRegular.woff") format("woff"),
    url("../fonts/UbuntuRegular/UbuntuRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "UbuntuBold"; */
@font-face {
    font-family: "Ubuntu";
    src: url("../fonts/UbuntuBold/UbuntuBold.eot");
    src: url("../fonts/UbuntuBold/UbuntuBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/UbuntuBold/UbuntuBold.woff") format("woff"),
    url("../fonts/UbuntuBold/UbuntuBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}
/* font-family: "UbuntuItalic"; */
@font-face {
    font-family: "Ubuntu";
    src: url("../fonts/UbuntuItalic/UbuntuItalic.eot");
    src: url("../fonts/UbuntuItalic/UbuntuItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/UbuntuItalic/UbuntuItalic.woff") format("woff"),
    url("../fonts/UbuntuItalic/UbuntuItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}
/* font-family: "UbuntuMedium"; */
@font-face {
    font-family: "Ubuntu";
    src: url("../fonts/UbuntuMedium/UbuntuMedium.eot");
    src: url("../fonts/UbuntuMedium/UbuntuMedium.eot?#iefix")format("embedded-opentype"),
    url("../fonts/UbuntuMedium/UbuntuMedium.woff") format("woff"),
    url("../fonts/UbuntuMedium/UbuntuMedium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}
/* font-family: "UbuntuLight"; */
@font-face {
    font-family: "Ubuntu";
    src: url("../fonts/UbuntuLight/UbuntuLight.eot");
    src: url("../fonts/UbuntuLight/UbuntuLight.eot?#iefix")format("embedded-opentype"),
    url("../fonts/UbuntuLight/UbuntuLight.woff") format("woff"),
    url("../fonts/UbuntuLight/UbuntuLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 200;
}
/* font-family: "UbuntuMediumItalic"; */
@font-face {
    font-family: "Ubuntu";
    src: url("../fonts/UbuntuMediumItalic/UbuntuMediumItalic.eot");
    src: url("../fonts/UbuntuMediumItalic/UbuntuMediumItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/UbuntuMediumItalic/UbuntuMediumItalic.woff") format("woff"),
    url("../fonts/UbuntuMediumItalic/UbuntuMediumItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 500;
}
/* font-family: "UbuntuLightItalic"; */
@font-face {
    font-family: "Ubuntu";
    src: url("../fonts/UbuntuLightItalic/UbuntuLightItalic.eot");
    src: url("../fonts/UbuntuLightItalic/UbuntuLightItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/UbuntuLightItalic/UbuntuLightItalic.woff") format("woff"),
    url("../fonts/UbuntuLightItalic/UbuntuLightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 200;
}
/* font-family: "UbuntuBoldItalic"; */
@font-face {
    font-family: "Ubuntu";
    src: url("../fonts/UbuntuBoldItalic/UbuntuBoldItalic.eot");
    src: url("../fonts/UbuntuBoldItalic/UbuntuBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/UbuntuBoldItalic/UbuntuBoldItalic.woff") format("woff"),
    url("../fonts/UbuntuBoldItalic/UbuntuBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: bold;
}
/* font-family: "UbuntuCondensedRegular"; */
@font-face {
    font-family: "Ubuntu";
    src: url("../fonts/UbuntuCondensedRegular/UbuntuCondensedRegular.eot");
    src: url("../fonts/UbuntuCondensedRegular/UbuntuCondensedRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/UbuntuCondensedRegular/UbuntuCondensedRegular.woff") format("woff"),
    url("../fonts/UbuntuCondensedRegular/UbuntuCondensedRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}