/* font-family: "GillSans"; */
@font-face {
    font-family: "GillSans";
    src: url("../fonts/GillSans/GillSans.eot");
    src: url("../fonts/GillSans/GillSans.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GillSans/GillSans.woff") format("woff"),
    url("../fonts/GillSans/GillSans.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "GillSans-Bold"; */
@font-face {
    font-family: "GillSans";
    src: url("../fonts/GillSans-Bold/GillSans-Bold.eot");
    src: url("../fonts/GillSans-Bold/GillSans-Bold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GillSans-Bold/GillSans-Bold.woff") format("woff"),
    url("../fonts/GillSans-Bold/GillSans-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}
/* font-family: "GillSans-BoldItalic"; */
@font-face {
    font-family: "GillSans";
    src: url("../fonts/GillSans-BoldItalic/GillSans-BoldItalic.eot");
    src: url("../fonts/GillSans-BoldItalic/GillSans-BoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GillSans-BoldItalic/GillSans-BoldItalic.woff") format("woff"),
    url("../fonts/GillSans-BoldItalic/GillSans-BoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: bold;
}
/* font-family: "GillSans-Italic"; */
@font-face {
    font-family: "GillSans";
    src: url("../fonts/GillSans-Italic/GillSans-Italic.eot");
    src: url("../fonts/GillSans-Italic/GillSans-Italic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GillSans-Italic/GillSans-Italic.woff") format("woff"),
    url("../fonts/GillSans-Italic/GillSans-Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: normal;
}
/* font-family: "GillSans-Light"; */
@font-face {
    font-family: "GillSans";
    src: url("../fonts/GillSans-Light/GillSans-Light.eot");
    src: url("../fonts/GillSans-Light/GillSans-Light.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GillSans-Light/GillSans-Light.woff") format("woff"),
    url("../fonts/GillSans-Light/GillSans-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 200;
}
/* font-family: "GillSans-LightItalic"; */
@font-face {
    font-family: "GillSans";
    src: url("../fonts/GillSans-LightItalic/GillSans-LightItalic.eot");
    src: url("../fonts/GillSans-LightItalic/GillSans-LightItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GillSans-LightItalic/GillSans-LightItalic.woff") format("woff"),
    url("../fonts/GillSans-LightItalic/GillSans-LightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 200;
}
/* font-family: "GillSans-SemiBold"; */
@font-face {
    font-family: "GillSans";
    src: url("../fonts/GillSans-SemiBold/GillSans-SemiBold.eot");
    src: url("../fonts/GillSans-SemiBold/GillSans-SemiBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GillSans-SemiBold/GillSans-SemiBold.woff") format("woff"),
    url("../fonts/GillSans-SemiBold/GillSans-SemiBold.ttf") format("truetype");
    font-style: italic;
    font-weight: 500;
}
/* font-family: "GillSans-SemiBoldItalic"; */
@font-face {
    font-family: "GillSans";
    src: url("../fonts/GillSans-SemiBoldItalic/GillSans-SemiBoldItalic.eot");
    src: url("../fonts/GillSans-SemiBoldItalic/GillSans-SemiBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GillSans-SemiBoldItalic/GillSans-SemiBoldItalic.woff") format("woff"),
    url("../fonts/GillSans-SemiBoldItalic/GillSans-SemiBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 500;
}
/* font-family: "GillSans-UltraBold"; */
@font-face {
    font-family: "GillSans";
    src: url("../fonts/GillSans-UltraBold/GillSans-UltraBold.eot");
    src: url("../fonts/GillSans-UltraBold/GillSans-UltraBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GillSans-UltraBold/GillSans-UltraBold.woff") format("woff"),
    url("../fonts/GillSans-UltraBold/GillSans-UltraBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
}